<template>
  <div class="electric-invoice-hand page-container">
    <PageTitle title="電子發票" />
    <p class="electric-invoice-hand_content mb-9">
      請確認以下輸入/拍照帶入之相關資訊<br />
      若資訊錯誤將導致審核未通過<br />
      需重新登錄完成審核
    </p>
    <!-- input -->
    <v-form ref="form" v-model="valid" class="invoice_form">
      <v-text-field
        v-model="number"
        placeholder="發票號碼 ex: AB12345678"
        type="text"
        solo
        required
        :rules="numberRules"
        class="invoice_input"
      ></v-text-field>
      <v-text-field
        v-model="code"
        placeholder="發票隨機碼 ex: 1234"
        type="text"
        solo
        required
        :rules="codeRules"
        class="invoice_input"
      ></v-text-field>
      <date-picker
        class="invoice_picker"
        v-model="date"
        valueType="format"
        :editable="false"
        :clearable="false"
        :disabled-date="disabledDate"
        placeholder="請選擇發票日期"
      >
        <!-- <i slot="icon-calendar">1</i> -->
      </date-picker>
    </v-form>
    <BaseBtn
      class="mt-11"
      text="送出登錄資料"
      :disabled="!valid"
      @click="submit"
    />
    <BaseBtn
      text="重新選擇登錄發票類型"
      type="back"
      :isOutlined="true"
      @click="routeTo('/invoiceLog')"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";
import form from "@/mixins/form.vue";

export default {
  name: "ElectricInvoice",
  mixins: [form],
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {
      valid: false,
      number: "",
      code: "",
      date: "",
      url: "",
    };
  },
  computed: {
    invoice() {
      return this.$store.state.invoice;
    },
  },
  mounted() {
    if (this.invoice.hasData) {
      this.number = this.invoice.data.invoiceNumber;
      this.code = this.invoice.data.randomCode;
      this.date = this.invoice.data.invoiceDate;
      this.url = this.invoice.data.imageUrl;
    }
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
    async submit() {
      this.$store.commit("updateLoading", true);
      let url = process.env.VUE_APP_APIUrl + "api/v1/users/get-user-token";
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      };
      await this.axios
        .post(url, data)
        .then((res) => {
          window.localStorage.setItem("jwt", res.data.data.token);
          let url =
            process.env.VUE_APP_APIUrl +
            "api/v1/auth/invoices/electric-invoices";
          let data = {
            invoiceNumber: this.number.toUpperCase(),
            invoiceDate: this.date,
            randomCode: this.code,
            imageUrl: this.url,
          };
          this.axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + window.localStorage.getItem("jwt"),
              },
            })
            .then((res) => {
              console.log(res);
              this.$store.commit("updateLoading", false);
              if (res.data.data.status === "待審核"){
                this.$router.push({ path: "result", query: { result: "1" } });
              } else if (!res.data.data.failCode) {
                this.$router.push({ path: "result", query: { result: "0" } });
              } else {
                // 00001 未購買活動商品
                // 00002 不符合活動期間
                // 00003 發票重複登錄
                // 00004 發票資訊錯誤，請重新上傳
                // 00005 發票類型錯誤，請重新上傳
                // 00006 其他
                const failList = {
                  "00001": "3",
                  "00002": "4",
                  "00003": "2",
                  "00004": "5",
                  "00005": "5",
                  "00006": "1",
                };
                const code = failList[res.data.data.failCode];
                this.$router.push({ path: "result", query: { result: code } });
              }
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("updateLoading", false);
              this.$router.push({ path: "result", query: { result: "5" } });
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss">
.electric-invoice-hand {
  .invoice_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
